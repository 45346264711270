<template>
  <div :class="[margin, extendedWrapperClasses]">
    <div :class="boxClasses" class="relative">
      <div v-if="showPrefix || $slots.prefix" class="prefix order-1">
        <icon
          v-if="backButton"
          :icon="backButtonIcon"
          class="prefix-icon"
          :class="prefixClasses"
          @mousedown="clickPrefix"
        />
        <div
          v-if="$slots.prefix"
          class="flex items-center"
          :class="prefixIconClasses"
        >
          <slot name="prefix" />
        </div>
      </div>
      <div
        :class="
          `relative flex-1 order-2 ${
            inputStyleType === 'inline'
              ? ' flex-row flex items-center justify-between px-4 py-2'
              : ''
          }`
        "
      >
        <input
          v-if="mask"
          :id="`input-text-${id}`"
          ref="input"
          v-mask="mask"
          :value="value"
          v-bind="$attrs"
          :v-on="$listeners"
          :class="inputClasses"
          :placeholder="placeholder || label"
          :disabled="isLoading || isDisabled"
          @keyup.enter="onPressEnter"
          @keypress="$emit('keypress', $event)"
          @focus="onFocus"
          @blur="onBlur"
          @input="$emit('input', $event.target.value)"
          @keydown="$emit('keydown', $event)"
          @paste="$emit('paste', $event)"
        />
        <input
          v-else
          :id="`input-text-${id}`"
          ref="input"
          data-label="input"
          :value="value"
          v-bind="$attrs"
          :v-on="$listeners"
          :class="[
            inputClasses,
            inputStyleType === 'inline' ? ' inline-input' : '',
          ]"
          :placeholder="placeholder || label"
          :disabled="isLoading || isDisabled"
          :type="type"
          :style="inputStyleType === 'inline' ? { padding: 0 } : {}"
          @keyup.enter="onPressEnter"
          @keypress="$emit('keypress', $event)"
          @focus="onFocus"
          @blur="onBlur"
          @click="onClick"
          @input="$emit('input', $event.target.value)"
          @keydown="$emit('keydown', $event)"
          @paste="$emit('paste', $event)"
        />
        <label
          v-if="withFloatingLabel"
          :class="labelClasses"
          :for="`input-text-${id}`"
          :style="
            inputStyleType === 'inline'
              ? { padding: 0, top: '35%', left: '1rem', width: 'auto' }
              : {}
          "
          @click="onClickLabel"
        >
          <template v-if="value || !placeholder">
            {{ label }}
          </template>
          <template v-else>
            {{ placeholder }}
          </template>
        </label>
        <div v-if="inputStyleType === 'inline'">
          <slot name="inline-button" />
        </div>
      </div>
      <div v-if="!hideSufix || $slots.icon" class="flex items-center order-3">
        <template v-if="clearable">
          <icon
            v-if="showSufix && !$slots.icon && value === ''"
            :icon="sufixIcon"
            color="success"
            :class="suffixIconClasses"
          />
          <span
            v-else
            class="text-info-light-dark ms-4 me-4 flex items-center end-0 cursor-pointer"
            data-test="clear-input"
            @mousedown="clearInput"
          >
            {{ $t('atoms.input.clear') }}
          </span>
        </template>
        <template v-else-if="!hasError">
          <icon
            v-if="showSufix && !$slots.icon"
            :icon="sufixIcon"
            color="success"
            :class="suffixIconClasses"
          />
        </template>
        <slot name="icon" />
      </div>
    </div>
    <small
      v-if="informationMessage.length && !$slots.customMessage"
      :class="{ 'text-error': hasError }"
      class="ms-1 inline-block"
      data-test="information-message"
      :data-test-information-message="dataTestInformationMessage || null"
    >
      {{ informationMessage }}
    </small>
    <slot name="customMessage" />
    <div
      v-if="hasSubscript"
      class="text-xxs text-gray-black my-1"
      :dir="direction"
    >
      <span v-if="subscriptKey"> {{ $t(`general.${subscriptKey}`) }}</span>
      <slot v-else />
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import uniqueId from '~/utils/uniqueId'

import Icon from '~/components/atoms/icon'

export default {
  components: {
    Icon,
  },
  inheritAttrs: false,
  props: {
    isSucceeded: Boolean,
    hasError: Boolean,
    hideSufix: Boolean,
    isDirty: Boolean,
    isBig: Boolean,
    isLoading: Boolean,
    isDisabled: Boolean,
    hasAutofocus: Boolean,
    focusAgain: Boolean,
    suffixIconType: VueTypes.string,
    suffixIconComponent: VueTypes.any,
    hasCustomSuffixIcon: VueTypes.bool.def(false),
    label: VueTypes.string.def(''),
    value: VueTypes.oneOfType([String, Number]).def(''),
    type: VueTypes.string.def('text'),
    uppercase: Boolean,
    margin: VueTypes.string.def('mb-4'),
    informationMessage: VueTypes.string.def(''),
    subscriptKey: {
      type: String,
      default: '',
    },
    withFloatingLabel: {
      type: Boolean,
      default: true,
    },
    extendedBoxClasses: {
      type: String,
      default: '',
    },
    extendedInputClasses: {
      type: String,
      default: '',
    },
    extendedWrapperClasses: {
      type: String,
      default: '',
    },
    extendedLabelClasses: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    backButton: {
      type: Boolean,
      default: false,
    },
    backButtonIcon: {
      type: String,
      default: '',
    },
    showPrefix: {
      type: Boolean,
      default: false,
    },
    prefixClasses: {
      type: String,
      default: '',
    },
    isPhoneInput: Boolean,
    mask: VueTypes.string,
    placeholder: VueTypes.string,
    direction: VueTypes.string.def('ltr'),
    lockDirection: {
      type: Boolean,
      default: false,
    },
    dataTestInformationMessage: {
      type: String,
      default: '',
    },
    inputStyleType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: uniqueId(),
      isFocused: this.isInFocus || false,
    }
  },
  computed: {
    hasSubscript() {
      return this.$slots.default || this.subscriptKey
    },
    showError() {
      return !this.isFocused && this.hasError
    },
    labelClasses() {
      const base =
        'absolute px-4 top-0 start-0 cursor-text truncate w-full select-none'

      let text = 'text-mono-mid'
      if (!this.isFocused && this.hasError) text = 'text-error'
      else if ((this.isFocused && this.isSelectable) || this.selected)
        text = 'text-link'
      const spacing = this.isBig ? 'p-4' : 'pt-3 px-4'
      return [base, text, spacing, this.extendedLabelClasses]
    },
    boxClasses() {
      const base = 'border w-full rounded appearance-none flex flex-row'
      let border =
        this.isFocused || this.selected
          ? 'border-secondary'
          : 'border-gray hover:border-gray-dark'

      if (this.backButton && this.isFocused) {
        border +=
          'border-secondary hover:border-gray-dark border-t-0 border-l-0 border-r-0'
      }

      const bg = 'bg-white'
      if (this.showError) border = 'border-error'
      else if (this.isLoading) border = 'opacity-50 border-gray'
      else if (this.isDisabled) border = 'border-gray-dark'
      else if (this.isSucceeded) border = 'border-success'
      if (!this.withFloatingLabel) {
        return [base, border, bg, this.extendedBoxClasses]
      }

      const variant = this.isBig ? 'label-floating-big' : 'label-floating'
      const selected =
        this.isFocused || this.selected ? 'shadow-border-xs-secondary' : ''
      return [base, bg, border, variant, selected, this.extendedBoxClasses]
    },
    inputClasses() {
      const base = 'w-full px-4 rounded'
      const spacing = this.isBig ? 'py-4 px-4' : 'py-3 px-4'
      const extraPadding = this.showPrefix ? 'px-6' : ''
      const adjustedPaddingForLabel =
        this.value && this.withFloatingLabel ? 'pt-6 pb-2' : ''

      let text = 'text-mono-darker placeholder:text-mono-mid'
      if (this.isSucceeded) text = 'text-mono-darker placeholder:text-mono-mid'
      else if (this.showError) text = 'text-mono-darker placeholder:text-error'
      else if (this.value) text = 'text-mono-mid'
      else if ((this.isFocused && this.isSelectable) || this.selected)
        text = 'text-link placeholder:text-link'

      const bg = ''
      const disabled = this.isDisabled ? 'cursor-not-allowed' : 'cursor-auto'
      return [
        base,
        text,
        bg,
        { uppercase: this.uppercase },
        disabled,
        spacing,
        extraPadding,
        adjustedPaddingForLabel,
        this.extendedInputClasses,
      ]
    },
    suffixIconClasses() {
      const base = 'ms-4 me-4 h-full flex items-center'
      /*
        RA: phone number input should always be forced to LTR, so in these cases we cannot use the end-0 utility class
        because that would align the icon on the wrong side of the input field
      */
      return [base, this.isPhoneInput ? 'right-0' : 'end-0']
    },
    prefixIconClasses() {
      const base = 'ms-4 h-full flex items-center'
      /*
        RA: phone number input should always be forced to LTR, so in these cases we cannot use the end-0 utility class
        because that would align the icon on the wrong side of the input field
      */
      return [base, this.isPhoneInput ? 'left-0' : 'start-0']
    },
    showSufix() {
      if (this.hideSufix) return false
      if (this.isSucceeded || this.isLoading || this.hasCustomSuffixIcon)
        return true

      return this.isDirty && !this.hasError
    },
    sufixIcon() {
      if (this.isLoading) return 'loader'
      if (this.isSucceeded) return 'check'
      if (this.hasCustomSuffixIcon) return this.suffixIconType
      return ''
    },
  },
  watch: {
    focusAgain(val) {
      if (val) this.focus()
    },
  },
  mounted() {
    if (this.hasAutofocus && !this.value) {
      this.onAutofocus()
    }
  },
  methods: {
    onFocus() {
      this.isFocused = true
      this.$emit('focus')
    },
    onBlur(event) {
      this.isFocused = false
      this.$emit('blur', event)
    },
    onClick() {
      this.$emit('click')
    },
    onAutofocus() {
      this.$refs.input.focus()
    },
    onClickLabel() {
      this.$refs.input.focus()
    },
    onPressEnter() {
      this.$refs.input.blur()
      this.$emit('blur')
    },
    focus() {
      this.$refs.input.focus()
    },
    clearInput(e) {
      e.preventDefault()
      this.focus()
      this.$emit('clear-input')
    },
    clickPrefix(e) {
      e.preventDefault()
      this.$emit('clicked-prefix', this.$refs.input)
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='date'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 3rem;
  display: block;
}

.label-floating-big input:not(:placeholder-shown) {
  @apply text-base;

  ~ label {
    @apply text-ls pt-2 leading-none;
    transition: all 0.2s ease-in-out;
  }
}

.label-floating-big input::placeholder {
  color: transparent;
}

.label-floating input:not(:placeholder-shown) {
  @apply pt-5 pb-2 text-base;

  ~ label {
    @apply text-ls pt-2 leading-none;

    transition: all 0.2s ease-in-out;

    &:not(.text-error) {
      @apply text-gray-black;
    }
  }
}

.label-floating input::placeholder {
  color: transparent;
}

.prefix-icon {
  left: 18px;
}

.inline-input {
  padding-top: 0.7rem !important;

  &:not(:placeholder-shown) ~ label {
    top: 7px !important;
    font-size: 0.75rem !important;
  }
}
</style>
