var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.margin, _vm.extendedWrapperClasses]},[_c('div',{staticClass:"relative",class:_vm.boxClasses},[(_vm.showPrefix || _vm.$slots.prefix)?_c('div',{staticClass:"prefix order-1"},[(_vm.backButton)?_c('icon',{staticClass:"prefix-icon",class:_vm.prefixClasses,attrs:{"icon":_vm.backButtonIcon},on:{"mousedown":_vm.clickPrefix}}):_vm._e(),_vm._v(" "),(_vm.$slots.prefix)?_c('div',{staticClass:"flex items-center",class:_vm.prefixIconClasses},[_vm._t("prefix")],2):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{class:`relative flex-1 order-2 ${
          _vm.inputStyleType === 'inline'
            ? ' flex-row flex items-center justify-between px-4 py-2'
            : ''
        }`},[(_vm.mask)?_c('input',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"input",class:_vm.inputClasses,attrs:{"id":`input-text-${_vm.id}`,"v-on":_vm.$listeners,"placeholder":_vm.placeholder || _vm.label,"disabled":_vm.isLoading || _vm.isDisabled},domProps:{"value":_vm.value},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onPressEnter.apply(null, arguments)},"keypress":function($event){return _vm.$emit('keypress', $event)},"focus":_vm.onFocus,"blur":_vm.onBlur,"input":function($event){return _vm.$emit('input', $event.target.value)},"keydown":function($event){return _vm.$emit('keydown', $event)},"paste":function($event){return _vm.$emit('paste', $event)}}},'input',_vm.$attrs,false)):_c('input',_vm._b({ref:"input",class:[
          _vm.inputClasses,
          _vm.inputStyleType === 'inline' ? ' inline-input' : '',
        ],style:(_vm.inputStyleType === 'inline' ? { padding: 0 } : {}),attrs:{"id":`input-text-${_vm.id}`,"data-label":"input","v-on":_vm.$listeners,"placeholder":_vm.placeholder || _vm.label,"disabled":_vm.isLoading || _vm.isDisabled,"type":_vm.type},domProps:{"value":_vm.value},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onPressEnter.apply(null, arguments)},"keypress":function($event){return _vm.$emit('keypress', $event)},"focus":_vm.onFocus,"blur":_vm.onBlur,"click":_vm.onClick,"input":function($event){return _vm.$emit('input', $event.target.value)},"keydown":function($event){return _vm.$emit('keydown', $event)},"paste":function($event){return _vm.$emit('paste', $event)}}},'input',_vm.$attrs,false)),_vm._v(" "),(_vm.withFloatingLabel)?_c('label',{class:_vm.labelClasses,style:(_vm.inputStyleType === 'inline'
            ? { padding: 0, top: '35%', left: '1rem', width: 'auto' }
            : {}),attrs:{"for":`input-text-${_vm.id}`},on:{"click":_vm.onClickLabel}},[(_vm.value || !_vm.placeholder)?[_vm._v("\n          "+_vm._s(_vm.label)+"\n        ")]:[_vm._v("\n          "+_vm._s(_vm.placeholder)+"\n        ")]],2):_vm._e(),_vm._v(" "),(_vm.inputStyleType === 'inline')?_c('div',[_vm._t("inline-button")],2):_vm._e()]),_vm._v(" "),(!_vm.hideSufix || _vm.$slots.icon)?_c('div',{staticClass:"flex items-center order-3"},[(_vm.clearable)?[(_vm.showSufix && !_vm.$slots.icon && _vm.value === '')?_c('icon',{class:_vm.suffixIconClasses,attrs:{"icon":_vm.sufixIcon,"color":"success"}}):_c('span',{staticClass:"text-info-light-dark ms-4 me-4 flex items-center end-0 cursor-pointer",attrs:{"data-test":"clear-input"},on:{"mousedown":_vm.clearInput}},[_vm._v("\n          "+_vm._s(_vm.$t('atoms.input.clear'))+"\n        ")])]:(!_vm.hasError)?[(_vm.showSufix && !_vm.$slots.icon)?_c('icon',{class:_vm.suffixIconClasses,attrs:{"icon":_vm.sufixIcon,"color":"success"}}):_vm._e()]:_vm._e(),_vm._v(" "),_vm._t("icon")],2):_vm._e()]),_vm._v(" "),(_vm.informationMessage.length && !_vm.$slots.customMessage)?_c('small',{staticClass:"ms-1 inline-block",class:{ 'text-error': _vm.hasError },attrs:{"data-test":"information-message","data-test-information-message":_vm.dataTestInformationMessage || null}},[_vm._v("\n    "+_vm._s(_vm.informationMessage)+"\n  ")]):_vm._e(),_vm._v(" "),_vm._t("customMessage"),_vm._v(" "),(_vm.hasSubscript)?_c('div',{staticClass:"text-xxs text-gray-black my-1",attrs:{"dir":_vm.direction}},[(_vm.subscriptKey)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(`general.${_vm.subscriptKey}`)))]):_vm._t("default")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }